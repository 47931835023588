@use "sass:math";

.textfield {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    margin: 2vh 0;

    width: 100%;

    .input-textfield-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;

        .icon-lamp {
            position: absolute;
            right: 1vw;
            cursor: pointer;
        }

        &:hover {
            background-color: var(--color-grey);
        }
    }

    .description-box {
        display: flex;
        align-items: center;
        justify-content: center;


        background-color: var(--color-green-300);
        margin-top: 1vh;
        
        border-radius: 8px;
        box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

        width: 100%;
        height:  15vh;


        p {
            margin: 0;
            color: var(--color-white);


            height: auto;
            padding: 4.8vh 3.5vw;
        }
    }


   

    .layout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &.btn-side-right {
            justify-content: flex-start;
            margin-left: 0.7vw;
            height: 4.5vh; // Aplicando a altura somente para esta variante
        }
        &.btn-side-left {
            margin-right: 0.7vw;
            height: 4.5vh;// Aplicando a altura somente para esta variante
        }
        &.btn-below {
            justify-content: flex-end;
            margin-top: 4.5vh;
            height: 7vh;
        }
        &.two-btn {
            // Estilos específicos para a variante two-btn
        }
    }

    
}

