.continuação{
    width: 100%;
    height: 100vh;

    padding: 0 16.8vh;

    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;

    background-color: var(--color-white);

    
        
    > label{
        font-size: 2.5vw;
        color: var(--color-bordor);
        font-weight: 600;

        margin-bottom: 3vh;
    }

    p {
        font-size: 1.8vw;
        font-weight: 400;
    }

    > span {
        margin-top: 1.5vh;


        font-size: 1.5vw;
        font-weight: 500;

        > p {
            font-size: 1.25vw;
            font-weight: 500;
        }
    }

    .map-container{
        width: 100%;
    }
    
    .ansewer-container{
        width: 100%;

        margin-top: 2vh;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        
    }

    .textfield {
        height: 50px;
    }

    .checkbox-container{
        width: 100%;

        margin-top: 2vh;

        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        > p{
            
            padding-left: 1.5vw;
        }

        > label{
            font-size: 1.8vw;
            color: var(--color-bordor);
            font-weight: 500;
    
            margin-bottom: 1.8vh;
        }

        .checkbox-option{
            width: 100%;

            padding: 0 5.8vw;
            margin-top: 1vh;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            .checkbox-option-label{
                padding-left: 0.8vw;

                display: flex;
                justify-content: flex-start;
                align-items: start;
                flex-direction: column;

                > span{
                    font-size: 1.25vw;
                    font-weight: 100;
                }
            }

        }
    }

    .btn-container{
        width: 100%;

        margin-top: 3.6vh;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}
