* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, input, button, a {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}

:root {
  --color-white: #ffffff;
  --color-black: #1F1F1F;
  --color-grey: #ebebeb;
  --color-placehoder: #9D9D9D;
  --color-backgrownd: #F0F0F0;
  --color-bordor: #691838;
  --color-green-10: #A1C4A4;
  --color-green-100: #76C77D;
  --color-green-200: #55AF5D;
  --color-green-300: #66923F;
  --color-green-400: #085F2B;
  --color-google: #D96445;
  --color-lilas-300: #C076B5;
  --color-lilas-600: #78356E;

}

html {
  font-size: 16px;
}