.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100vh;

    .container-text{
        height: 70%;
    }

    button {
        z-index: 1;
    }
}