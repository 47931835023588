.home-container {

    height: 90vh;

    display: flex;
    align-items: center;
    justify-content: center;


    img {
        height: auto;
        width: 35vw;

        margin-right: 7.8vw;
        margin-top: 4.8vh;
    }

    .left-size{
        margin-left : 4.6vw;    

        display: flex;
        align-items:flex-start;
        justify-content: center;
        flex-direction: column;

        .home-text {
            margin-bottom: 1.2vh;
    
            label {
                font-size: 2.5vw;
                color: #182369;
                margin-bottom: 0.6vh;
    
                
                strong {
                    font-weight: 600;
                }
            }
    
            p {
                font-size: 1.25vw;
                font-weight: 100;
                color: var(--color-black);
                padding-right: 7.8vw;
            }
        }

        .textfield {
            display: flex;
            flex-direction: row !important;

            input {
                width: 27.3vw;
            }

        }

        
        .instagram-text {
            font-size: 1.25vw;
            color: #45347E;
            margin-bottom: 1.5vh;
        
            a {
                color: #493882;
                font-weight: bold;
                text-decoration: none;
        
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        
        .gmail-button {
            color: var(--color-white);
            background-color: var(--color-google);

            width: 20vw;
        }

    }

}
.gradient-box {
    width: 100%;
    background: linear-gradient(to right, #AD9FF7, #B9D9FF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .gradient-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        max-width: 1200px;
        width: 100%;
        justify-content: center;
        padding: 20px 0;

        p {
            font-size: 22px;
            margin: 0;
            color: #353E7B;
            text-align: center;
            strong {
                font-weight: bold;
            }
        }

        .button-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 200px;
            button {
                font-size: 16px;
                width: 200px;

            }
            small {
                font-size: 12px;
                text-align: center;
                color: #4c316fa9;
                margin-top: 5px;
            }
        }
    }
}
.gradient-box .gradient-content .button-section small .highlight {
    color: #ff0e0e65;
    font-weight: bold; 
}
.home-footer {
    width: 100vw;
    height: 26vh;

    padding: 0 3.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    

    background-color: var(--color-lilas-600);
    color: var(--color-white);


    .faq {
        padding: 2.4vh 0;

        label {
            font-size: 1.5vw;
            font-weight: 500;
            margin-bottom: 0.8vh;
        }
    
        .faq-container {
            font-size: 1vw;

        }

    }

    .contact {
        padding: 2.4vh 0;

        &::before {
            content: " ";
            height: 10vh;
            width: 0.078vw;
            background-color: var(--color-white);
        }

        label {
            font-size: 1.5vw;
            font-weight: 500;
            margin-bottom: 0.8vh;
        }
    
        .contact-container {
            font-size: 1vw;

            li {

                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            img {
                height: 3vh;
                width: auto;

                margin-right: 0.8vh;
                margin-bottom: 0.8vh;

            }
    

        }

    }
}