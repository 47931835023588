.freeflow{
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-green-10);

    .freeflow-container{
        width: 56.5vw;
        height: auto;

        padding: 2.3vw 3.6vh;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background-color: var(--color-white);

        border-radius: 8px;
        box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.25);


        
        > label{
            font-size: 1.8vw;
            color: var(--color-bordor);
            font-weight: 500;
        }


        input {
            width: 49vw;
        }


    }
}