.checkbox-input {
    display: flex;
    flex-direction: column;
  .option{
    display: flex;
    flex-direction: row;

    p { margin-left: 10px;}
  }
    label {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
  
      input[type="checkbox"] {
        margin-right: 10px;
      }
    }
  
    input[type="text"] {
      margin-left: 25px;
      margin-top: 5px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    .outros{
      display: flex;
      flex-direction: row;
  
  
    }
  }
  