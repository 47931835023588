.button {
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 1.2vw 3.2vh;

    border-radius: 8px;
    border: none;
    
    width: 10vw;
    height: 6vh;

    cursor: pointer;

    label {
        font-size: 2vh;
        font-weight: 600;
    }
  
    &:hover{
        transform: scale(1.001);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);;
    }
}



.button-border-red {
    border: 2px solid var(--color-bordor);
    background: transparent;   
    color: var(--color-bordor); 
}

.button-border-green {
    border: 2px solid var(--color-green-300);
    background: transparent;   
    color: var(--color-green-300); 
}

.button-filled-green{
    background-color: var(--color-green-300);
    color: var(--color-white);
}

.button-filled-lilas{
    background-color: var(--color-lilas-300);
    color: var(--color-white);
}

.button-filled-red{
    background-color: var(--color-bordor);
    color: var(--color-white);
}


