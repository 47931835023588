.input-container  {

    width: 100%;
    height: 6vh;
    padding: 1.2vh 1.5vw;

    border-radius: 8px;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover{
        background-color: var(--color-grey);
    }

    i {
        color: var(--color-placehoder);
        margin: 1vh 0;
    }
 
    .password-input {
        border: none;
        margin-left: 0.4vw;
        max-width: 100%;

        background-color: transparent;
        color: var(--color-black);

        &:focus{
            outline: none;
        }

        &::placeholder {
            color: var(--color-placehoder);
        }
    }



}





